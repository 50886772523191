import "spark-bootstrap";
import "./components/nav/headband.js";
import "./components/pricing.js";
import "./components/contactForm.js";
import "./components/listMap.js";
import "./components/mapCard.js";
import "./components/customMapCard.js";
import "./components/informationMap.js";
import "./components/metamaskButton.js";
import "./spark-components/modals/premiumModal.js";
import "./spark-components/notifications/notifications.js";
import "./spark-components/auth/register-stripe.js";
import spark from "spark";
import "owl.carousel";
/*
 |--------------------------------------------------------------------------
 | Laravel Spark Bootstrap
 |--------------------------------------------------------------------------
 |
 | First, we will load all of the "core" dependencies for Spark which are
 | libraries such as Vue and jQuery. This also loads the Spark helpers
 | for things such as HTTP calls, forms, and form validation errors.
 |
 | Next, we'll create the root Vue application for Spark. This will start
 | the entire application and attach it to the DOM. Of course, you may
 | customize this script as you desire and load your own components.
 |
 */
import "../css/app.css";
import "../sass/app.scss";
// custom component
import FormInput from "./components/form/Input.vue";
import FormSelect from "./components/form/Select.vue";
import FormDatepicker from "./components/form/Datepicker.vue";
import FormToggle from "./components/form/Toggle.vue";
import FrontpageSolutions from "./components/frontpage/solutions.vue";
import { posthogClient } from "./posthog/posthog-init";
[FormInput, FormSelect, FormDatepicker, FormToggle, FrontpageSolutions].forEach((element) => {
    Vue.component(element.name, element);
});
var app = new Vue({
    mixins: [spark],
});
